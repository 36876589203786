import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { useAuth } from 'lib/auth';
import { Form, InputField } from 'components/form';
import { Button } from 'components/elements';
import { AuthLayout } from '../components/AuthLayout';

const schema = z.object({
  first_name: z.string().min(1, 'Required'),
  last_name: z.string().min(1, 'Required'),
  username: z.string().min(1, 'Required'),
  email: z.string().min(1, 'Required'),
  password: z.string().min(1, 'Required'),
  password2: z.string().min(1, 'Required'),
});

export const CreateAccount = () => {
  const { register, isRegistering } = useAuth();

  const navigate = useNavigate();

  return (
    <AuthLayout option="beta/v1/signup">
      <div className="">
        <h1 className="font-bold text-4xl my-12">Create new Account</h1>
        <Form
          className=""
          onSubmit={async values => {
            console.log(values);
            await register(values);
            navigate('/');
          }}
          schema={schema}
          options={{
            shouldUnregister: true,
          }}
        >
          {({ register, formState }) => (
            <>
              <InputField
                type="text"
                label="First Name"
                error={formState.errors['first_name']}
                registration={register('first_name')}
              />
              <InputField
                type="text"
                label="Last Name"
                error={formState.errors['last_name']}
                registration={register('last_name')}
              />
              <InputField
                type="text"
                label="Username"
                error={formState.errors['username']}
                registration={register('username')}
              />
              <InputField
                type="email"
                label="Email Address"
                error={formState.errors['email']}
                registration={register('email')}
              />
              <InputField
                type="password"
                label="Password"
                error={formState.errors['password']}
                registration={register('password')}
              />
              <InputField
                type="password"
                label="Confirm Password"
                error={formState.errors['password2']}
                registration={register('password2')}
              />
              <div>
                <Button
                  isLoading={isRegistering}
                  type="submit"
                  className="w-full"
                >
                  Register
                </Button>
              </div>
            </>
          )}
        </Form>
      </div>
    </AuthLayout>
  );
};
