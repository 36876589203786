import React from 'react';
import { AuthLayout } from '../components/AuthLayout';
import { Button } from 'components/elements';
import { Link } from 'react-router-dom';

export const ForgotPassword = () => {
  return (
    <AuthLayout option="beta/v1/signup">
      <div className="">
        <h1 className="font-bold text-4xl my-12">Forgot Password?</h1>
        <form className="w-full flex items-center flex-col gap-6">
          <input
            type="email"
            className="border border-solid bg-gray-50 border-gray-300 rounded-full w-full outline-none focus:outline-none px-8 py-1.5"
            placeholder="Email address"
          />

          <Button
          // onClick={handleSubmit}
          >
            recover password
          </Button>
          <Link
            to="/auth/login"
            className="rounded-full w-fit outline-none focus:outline-none px-8 py-1.5"
          >
            Back to login
          </Link>
        </form>
      </div>
    </AuthLayout>
  );
};
