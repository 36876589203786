import React from 'react';
import { Link } from 'react-router-dom';

export const Hero = () => {
  return (
    <div className="text-center py-36 space-y-10 px-8">
      <div className="space-y-2">
        <h1 className="text-white text-5xl md:text-[5rem]">
          Take Control of Your
          <br />
          <span className="text-orange-500 text-[2.0rem] md:text-[5rem] font-bold">
            Investme
            <span className="underline decoration-4 underline-offset-[15px] decoration-[#FE5F00]">
              nts .
            </span>
          </span>
        </h1>
      </div>
      <div className="w-full md:w-1/2 mx-auto">
        <p className="text-white font-semibold md:font-bold text-sm md:text-xl">
          We are passionate about empowering retail investors and democratizing
          access to financial tools
        </p>
      </div>
      <div>
        <Link
          to="/waitlist"
          className="text-white bg-flamingo-500 hover:bg-flamingo-600 px-4 py-4 rounded text-sm font-semibold"
        >
          JOIN OUR WAITLIST
        </Link>
      </div>
    </div>
  );
};
