import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const deleteActiveTrade = ({ tradeId }) => {
  return axios.delete(`portfolio/trades/${tradeId}/`);
};

deleteActiveTrade.propTypes = {
  tradeId: PropTypes.string.isRequired,
};

export const useDeleteActiveTrade = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async deletedTrade => {
      await queryClient.cancelQueries('activeTrades');

      const previousTrades = queryClient.getQueryData('activeTrades');

      queryClient.setQueryData(
        'activeTrades',
        previousTrades?.filter(trade => trade.id !== deletedTrade.tradeId)
      );

      return { previousTrades };
    },
    onError: (_, __, context) => {
      if (context?.previousTrades) {
        queryClient.setQueryData('activeTrades', context.previousTrades);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('activeTrades');
      addNotification({
        type: 'success',
        title: 'Successfully removed trade',
      });
    },
    ...config,
    mutationFn: deleteActiveTrade,
  });
};
