import React, { useEffect } from 'react';
import { Hero } from '../components/Hero';
import { Portfolio } from '../components/Portfolio';
import { Features } from '../components/Features';
import { Solutions } from '../components/Solutions';
import { MainLayout } from 'components/layout';
import storage from 'utils/storage';

export const Landing = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    storage.clearStorage();
  }, []);
  return (
    <>
      <MainLayout headerContent={<Hero />}>
        {/* Cater for space btn portfolio section and hero */}
        {/* <div className='h-20 w-full -mt-'></div> */}
        {/* Portifolio section */}
        <Portfolio />

        {/* Features section */}
        <Features />

        {/* Targeted Solutions section */}
        <Solutions />
      </MainLayout>
    </>
  );
};
