import { Button } from 'components/elements';
import { ContentLayout } from 'components/layout';
import React from 'react';
import { CreatePortfolio as CreatePortfolioDrawer } from '../components/CreatePortfolio';

export const CreatePortfolio = () => {
  return (
    <ContentLayout
      page="portfolios"
      headerContentLeft={
        <div className="text-3xl font-semibold md:font-bold">
          Create a new Portfolio
        </div>
      }
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div
          className="rounded-md bg-gray-100 dark:bg-dim-200 dark:text-gray-200 py-24
         px-12 space-y-4 text-gray-500"
        >
          <div className="font-black">Securely connect with your brokerage</div>
          <p className="h-32">
            Connect your brokerage account and import your portfolio within
            minutes.
          </p>
          <Button
            className="w-full py-5"
            variant="outline"
            disabled
            style={{ cursor: 'not-allowed' }}
          >
            CONNECT
          </Button>
        </div>

        <div className="rounded-md bg-gray-100 dark:bg-dim-200 dark:text-gray-200 py-24 px-12 space-y-4 text-gray-500">
          <div className="font-black">Track your holdings manually</div>
          <p className="h-32">
            Add your holdings information by simply adding a ticker, quantity of
            shares, and average price per share.
          </p>
          <CreatePortfolioDrawer title="CREATE PORTFOLIO" />
        </div>
      </div>
    </ContentLayout>
  );
};
