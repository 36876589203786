import React, { useState } from 'react';
import logo from 'assets/logo-dark.svg';
import darklogo from 'assets/logo.png';
import {
  FiActivity,
  FiAirplay,
  FiInfo,
  FiList,
  FiShoppingBag,
  FiXCircle,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from 'lib/auth';
import { useDarkMode } from 'hooks/useDarkMode';
import { useNotifications } from '../api/getNotifications';
import { ColorSwitch } from './ColorSwitch';
import { BiBell } from 'react-icons/bi';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const SideBar = ({ page, sidebarOpen, setSidebarOpen }) => {
  const [active] = useState(page);

  const notificationsQuery = useNotifications();
  const { user } = useAuth();
  const darkMode = useDarkMode();

  if (notificationsQuery.isLoading) {
    return <></>;
  }

  if (!notificationsQuery.data) {
    return null;
  }

  const countUnreadNotifications = () => {
    if (!notificationsQuery.data) {
      return 0;
    }
    return notificationsQuery.data.filter(not => !not.clicked).length;
  };

  return (
    <div className="bg-accent-500 py-8 px-8 h-screen static dark:bg-dim-400">
      <div className="flex flex-row-reverse my-4 md:hidden">
        <button onClick={() => setSidebarOpen(!sidebarOpen)}>
          <FiXCircle className="h-8 w-auto" />
        </button>
      </div>
      <Link to={user ? '/app' : '/'}>
        <img
          src={darkMode[0] ? darklogo : logo}
          alt="logo"
          className="h-8 w-auto mx-auto"
        />
      </Link>
      <div className="my-20 space-y-4">
        <Link
          to="/app"
          className={classNames(
            active === 'overview'
              ? 'bg-flamingo-500 text-white'
              : 'dark:text-gray-300',
            `flex items-center text-base space-x-4 py-3 px-10 
                 rounded-lg hover:bg-flamingo-500 hover:text-white`
          )}
        >
          <FiAirplay />
          <p>Overview</p>
        </Link>
        <Link
          to="/app/portfolios"
          className={classNames(
            active === 'portfolios'
              ? 'bg-flamingo-500 text-white'
              : 'dark:text-gray-300',
            `flex items-center text-base space-x-4 py-3 px-10 
                 rounded-lg hover:bg-flamingo-500 hover:text-white`
          )}
        >
          <FiActivity />
          <p>Portfolio</p>
        </Link>
        <Link
          to="/app/watchlist"
          className={classNames(
            active === 'watchlist'
              ? 'bg-flamingo-500 text-white'
              : 'dark:text-gray-300',
            `flex items-center text-base space-x-4 py-3 px-10 
                 rounded-lg hover:bg-flamingo-500 hover:text-white`
          )}
        >
          <FiList />
          <p>Watchlist</p>
        </Link>
        <Link
          to="/app/trades/logs"
          className={classNames(
            active === 'trades/logs'
              ? 'bg-flamingo-500 text-white'
              : 'dark:text-gray-300',
            `flex items-center text-base space-x-4 py-3 px-10 
                 rounded-lg hover:bg-flamingo-500 hover:text-white`
          )}
        >
          <FiShoppingBag />
          <p>Trade logs</p>
        </Link>
        <Link
          to="/app/notifications"
          className={classNames(
            active === 'notifications'
              ? 'bg-flamingo-500 text-white'
              : 'dark:text-gray-300',
            `flex items-center text-base space-x-4 py-3 px-10 
                 rounded-lg hover:bg-flamingo-500 hover:text-white`
          )}
        >
          <BiBell />
          <p className="flex flex-row items-center space-x-2">
            <span>Notifications</span>
            <span className="ml-2 inline-block whitespace-nowrap rounded-full shadow bg-danger-100 px-[0.65em] pb-[0.25em] pt-[0.35em] text-center align-baseline text-[0.75em] font-bold leading-none text-danger-700">
              {countUnreadNotifications()}
            </span>
          </p>
        </Link>
        <Link
          to="/app/support"
          className={classNames(
            active === 'support'
              ? 'bg-flamingo-500 text-white'
              : 'dark:text-gray-300',
            `flex items-center text-base space-x-4 py-3 px-10 
                 rounded-lg hover:bg-flamingo-500 hover:text-white`
          )}
        >
          <FiInfo />
          <p>Support</p>
        </Link>
      </div>

      <ColorSwitch />
    </div>
  );
};

SideBar.propTypes = {
  page: PropTypes.string,
  sidebarOpen: PropTypes.string,
  setSidebarOpen: PropTypes.string,
};
