import { Navigate, Route, Routes } from 'react-router-dom';
import { Signin } from './Signin';
import { CreateAccount } from './CreateAccount';
import { ForgotPassword } from './ForgotPassword';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Signin />} />
      <Route path="/beta/v1/signup" element={<CreateAccount />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
