import { lazyImport } from 'utils/lazyImport';

const { ContactUsRoutes } = lazyImport(
  () => import('features/contact'),
  'ContactUsRoutes'
);

const { WaitlistRoutes } = lazyImport(
  () => import('features/waitlist'),
  'WaitlistRoutes'
);

const { AboutUsRoutes } = lazyImport(
  () => import('features/about'),
  'AboutUsRoutes'
);

const { BlogRoutes } = lazyImport(() => import('features/blog'), 'BlogRoutes');

const { AuthRoutes } = lazyImport(() => import('features/auth'), 'AuthRoutes');

export const publicRoutes = [
  {
    path: '/contact-us/*',
    element: <ContactUsRoutes />,
  },
  {
    path: '/waitlist/*',
    element: <WaitlistRoutes />,
  },
  {
    path: '/about/*',
    element: <AboutUsRoutes />,
  },
  {
    path: '/blog/*',
    element: <BlogRoutes />,
  },
  {
    path: '/auth/*',
    element: <AuthRoutes />,
  },
];
