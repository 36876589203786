import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import logoImage from 'assets/logo.svg';
import mobileLogo from 'assets/logo-mobile.svg';
import { Link } from 'react-router-dom';
import { useAuth } from 'lib/auth';

export const HomeNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { user } = useAuth();

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="uppercase">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* Mobile menu button */}
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={toggleNavbar}
            >
              <span className="sr-only">Open main menu</span>
              {/* Icon when menu is closed */}
              <svg
                className={`block h-6 w-6 ${isOpen ? 'hidden' : 'block'}`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              {/* Icon when menu is open */}
              <svg
                className={`block h-6 w-6 ${isOpen ? 'block' : 'hidden'}`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <Link
              to={user ? '/app' : '/'}
              className="flex-shrink-0 flex items-center"
            >
              <img
                className="block lg:hidden h-6 w-auto mr-24"
                src={mobileLogo}
                alt="Tickerzone Logo"
              />
              <img
                className="hidden lg:block h-8 w-auto"
                src={logoImage}
                alt="Tickerzone Logo"
              />
            </Link>
            <div className="hidden sm:block sm:ml-6">
              <div className="flex space-x-4">
                <Link
                  to="/about"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  About
                </Link>
                <Link
                  to="/contact-us"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  Contact
                </Link>
                <Link
                  to="/blog"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  Blog
                </Link>
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex space-x-2 items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <Link
              to="/auth/login"
              className="text-gray-300 uppercase hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-xs  
              font-medium"
            >
              Log in
            </Link>
            <Link
              to="/waitlist"
              className="text-white uppercase bg-flamingo-500 px-3 py-2 rounded-md text-xs font-medium"
            >
              join waitlist
            </Link>
          </div>
        </div>
      </div>
      {/* Mobile menu, show/hide based on menu state */}
      <Transition
        show={isOpen}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        {ref => (
          <div className="sm:hidden" id="mobile-menu">
            <div
              ref={ref}
              className="px-2 pt-2 pb-3 space-y-1 bg-gray-900"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="main-menu"
            >
              <Link
                to="/about"
                className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                role="menuitem"
              >
                About
              </Link>
              <Link
                to="/contact-us"
                className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                role="menuitem"
              >
                Contact
              </Link>
              <Link
                to="/blog"
                className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                role="menuitem"
              >
                Blog
              </Link>
            </div>
          </div>
        )}
      </Transition>
    </nav>
  );
};
