import { FullScreenLoader } from 'components/elements';
import {
  loginWithEmailAndPassword,
  getUser,
  registerWithEmailAndPassword,
} from 'features/auth';
import storage from 'utils/storage';
import { initReactQueryAuth } from 'react-query-auth';
import { getPortfolios } from 'features/portfolios';

async function handleUserResponse(data) {
  const { access, refresh } = data;
  storage.setToken(access);
  storage.setRefreshToken(refresh);
  return access;
}

async function loadUser() {
  let currentToken = storage.getToken();

  if (currentToken) {
    const data = await getUser();
    return data;
  }
  return null;
}

async function loginFn(data) {
  const response = await loginWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  if (user) {
    const portfolios = await getPortfolios();
    if (!portfolios.length) {
      window.location.assign('/on-boarding/portfolio/create');
    }
    if (portfolios.length) {
      // Sort the portfolios in descending order based on portfolio_id
      portfolios.sort((a, b) => b.portfolio_id - a.portfolio_id);
      storage.setActivePortfolio(portfolios[0]?.portfolio_id);
    }
  }
  return user;
}

async function registerFn(data) {
  const response = await registerWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function logoutFn() {
  storage.clearToken();
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return <FullScreenLoader />;
  },
};

export const { AuthProvider, useAuth } = initReactQueryAuth(authConfig);
