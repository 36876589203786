import { MoonIcon, SunIcon } from '@heroicons/react/outline';
import { useDarkMode } from 'hooks/useDarkMode';
import React from 'react';

export const ColorSwitch = () => {
  const [darkMode, toggleDarkMode] = useDarkMode();

  return (
    <div>
      <div className="flex rounded-full bg-gray-400 justify-between w-fit mx-auto">
        {/* Dark Mode Icon */}
        <button
          onClick={toggleDarkMode}
          className={` ${
            !darkMode ? `rounded-full bg-white text-gray-600 shadow-lg` : ``
          } flex space-x-2 text-xs items-center text-gray-100 transition duration-300
         py-1 px-3 transition`}
        >
          <SunIcon className={`w-4 h-4 black`} />
          <p>Light</p>
        </button>
        <button
          onClick={toggleDarkMode}
          className={` ${
            darkMode ? `rounded-full bg-dim-300 shadow-lg` : ``
          } flex space-x-2 text-xs items-center text-gray-100 transition duration-300
         py-1 px-3`}
        >
          <MoonIcon className={`w-4 h-4 black`} />
          <p>Dark</p>
        </button>
      </div>
    </div>
  );
};
