import clsx from 'clsx';

import { FieldWrapper } from './FieldWrapper';

export const InputField = props => {
  const {
    type = 'text',
    label,
    className,
    maxLength,
    caption,

    registration,
    error,
  } = props;

  // limit image dimensions to 50x50

  const handleInput = event => {
    const { value } = event.target;
    if (type === 'number' && value.startsWith('-')) {
      // Prevent negative values for input of type "number"
      event.target.value = value.replace(/-/g, ''); // Remove all '-' characters
    }
  };

  const handleChange = event => {
    const { value } = event.target;
    if (type === 'number' && value.startsWith('-')) {
      // Prevent negative values for input of type "number"
      event.target.value = value.replace(/-/g, ''); // Remove all '-' characters
    }
  };

  return (
    <FieldWrapper label={label} error={error}>
      <div className="text-xs my-1 text-gray-400">{caption}</div>
      <input
        type={type}
        maxLength={maxLength}
        className={clsx(
          'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm',
          className
        )}
        onInput={handleInput} // Handle input to block negative values
        onChange={handleChange}
        {...registration}
      />
    </FieldWrapper>
  );
};
