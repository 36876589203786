import { Form, InputField } from 'components/form';
import { useAuth } from 'lib/auth';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import * as z from 'zod';
import { AuthLayout } from '../components/AuthLayout';
import { Button } from 'components/elements';

const schema = z.object({
  username: z.string().min(1, 'Required'),
  password: z.string().min(1, 'Required'),
});

export const Signin = () => {
  const { login, isLoggingIn } = useAuth();

  const navigate = useNavigate();

  return (
    <AuthLayout option="login">
      <div className="">
        <h1 className="font-bold text-4xl my-12">Sign in to Account</h1>
        <Form
          onSubmit={async values => {
            await login(values);
            navigate('/app');
          }}
          schema={schema}
        >
          {({ register, formState }) => (
            <>
              <InputField
                type="text"
                label="Username or email"
                error={formState.errors['username']}
                registration={register('username')}
              />
              <InputField
                type="password"
                label="Password"
                error={formState.errors['password']}
                registration={register('password')}
              />
              <div>
                <Button
                  isLoading={isLoggingIn}
                  type="submit"
                  className="w-full"
                >
                  Sign in
                </Button>
              </div>
            </>
          )}
        </Form>
        <div className="text-blue-600 py-4 w-full flex justify-center hover:text-blue-800">
          <Link to="/auth/forgot-password">Forgot password ?</Link>
        </div>
      </div>
    </AuthLayout>
  );
};
