import React, { useState, useEffect } from 'react';
import './pricechart.css';

export const PriceChart = ({ entryPrice, targetPrice, currentPrice }) => {
  // Calculate the percentage progress towards the target price
  const progress =
    ((currentPrice - entryPrice) / (targetPrice - entryPrice)) * 100;

  // Define the colors for the lines and zones
  const entryColor = '#804E4E';
  const targetColor = '#48856F';
  const currentColor = '#E8D39B';
  const archivedTargetColor = '#48856F';
  const redZoneColor = '#FE9F9F';
  const greenZoneColor = '#B2F5EA'; // Light shade of green
  const blueZoneColor = '#B2DFF5'; // Light shade of blue

  // Define the chart's width and height
  const chartWidth = 100;
  const chartHeight = 16; // Increased height to accommodate the arrow and caption

  // Calculate the x-coordinate for the entry price line
  const entryX = chartWidth / 4;

  // Calculate the x-coordinate for the target price line
  const targetX = (chartWidth / 4) * 3;

  // Calculate the x-coordinate for the current price line
  const currentX =
    progress <= 100 ? entryX + (targetX - entryX) * (progress / 100) : targetX;

  // Calculate the y-coordinate for the star in the middle of the line
  const starY = chartHeight / 2;

  // State to control blinking visibility for star and arrow
  const [isBlinking, setIsBlinking] = useState(true);

  // Toggle the blinking of star and arrow every 1 second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsBlinking(prevIsBlinking => !prevIsBlinking);
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Check if the target price has been achieved
  const isTargetAchieved = currentPrice === targetPrice;

  return (
    <div
      className={`border border-gray-200 rounded-full shadow hover:bg-gray-200 ${
        isTargetAchieved ? 'blinking-box' : ''
      }`}
    >
      <svg width={chartWidth} height={chartHeight}>
        {/* Red Zone (between entry price and current price) */}
        {currentPrice < entryPrice && (
          <rect
            x={currentX}
            y="0"
            width={entryX - currentX}
            height={chartHeight}
            fill={redZoneColor}
          />
        )}

        {/* Green Zone (between current price and target price) */}
        {progress >= 0 && progress <= 100 && (
          <rect
            x={currentX}
            y="0"
            width={targetX - currentX}
            height={chartHeight}
            fill={greenZoneColor}
          />
        )}

        {/* Blue Zone (between entry price and target price) */}
        {currentPrice >= entryPrice && currentPrice <= targetPrice && (
          <rect
            x={entryX}
            y="0"
            width={currentX - entryX}
            height={chartHeight}
            fill={blueZoneColor}
          />
        )}

        {/* Entry Price Line */}
        <line
          x1={entryX}
          y1="0"
          x2={entryX}
          y2={chartHeight}
          stroke={entryColor}
          strokeWidth="2"
        />

        {/* Target Price Line */}
        <line
          x1={targetX}
          y1="0"
          x2={targetX}
          y2={chartHeight}
          stroke={isTargetAchieved ? archivedTargetColor : targetColor}
          strokeWidth="2"
        />

        {/* Arrow */}
        <polygon
          points={`${targetX - 5},0 ${targetX},${chartHeight - 14} ${
            targetX + 5
          },0`}
          fill={isTargetAchieved ? archivedTargetColor : targetColor}
          stroke="#fff"
          strokeWidth="1"
          style={{
            animation: isBlinking ? 'blink-animation 1s infinite' : 'none',
          }}
        />

        {/* Current Price Line */}
        <line
          x1={currentX}
          y1="0"
          x2={currentX}
          y2={chartHeight}
          stroke={currentColor}
          strokeWidth="2"
        />

        {/* Blinking Star */}
        {isBlinking && (
          <polygon
            points={`${currentX - 5},${starY} ${currentX},${starY - 5} ${
              currentX + 5
            },${starY} ${currentX},${starY + 5}`}
            fill={currentColor}
            stroke="#fff"
            strokeWidth="1"
            style={{
              animation: 'blink-animation 1s infinite',
            }}
          />
        )}
      </svg>
      {/* Display caption if target price is achieved */}
      {isTargetAchieved && (
        <div className="caption text-xs">Archived target</div>
      )}
    </div>
  );
};
