import { Dialog, Transition } from '@headlessui/react';
import { useDarkMode } from 'hooks/useDarkMode';
import React from 'react';
import logo from 'assets/logo-dark.svg';
import darklogo from 'assets/logo.svg';
import { XIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

export const MobileViewLayout = ({ children, open, onclose }) => {
  const darkMode = useDarkMode();
  return (
    <div>
      <Transition.Root show={open} as={React.Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden z-40"
          open={open}
          onClose={onclose}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />
            <div className="fixed inset-y-0 left-0 pr-28 w-72 max-w-full flex">
              <Transition.Child
                as={React.Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-300"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="w-screen max-w-xl">
                  <div
                    className={` ${
                      darkMode[0]
                        ? `bg-dim-400 text-white divide-gray-700`
                        : `bg-white divide-gray-200`
                    } h-full divide-y flex flex-col shadow-xl`}
                  >
                    <div className="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                      <div className="px-4 sm:px-6">
                        <div className="flex justify-end">
                          <div className="ml-3 h-7 flex items-center">
                            <button
                              className={` ${
                                darkMode[0] ? `bg-dim-100` : 'bg-white'
                              } rounded-md text-gray-400 hover:text-gray-500 
                            focus:outline-none focus:ring-2 focus:ring-indigo-500`}
                              onClick={onclose}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mt-24 relative flex-1 px-4 sm:px-6">
                        <Link to="/app/">
                          <img
                            src={darkMode[0] ? darklogo : logo}
                            alt="logo"
                            className="h-8 w-auto mx-auto"
                          />
                        </Link>
                        {children}
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};
