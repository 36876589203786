import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getActiveTrades = ({ selectedPortfolio }) => {
  return axios.get(
    `/portfolio/trades/positions?portfolio_pk=${selectedPortfolio}`
  );
};

export const useActiveTrades = ({ selectedPortfolio, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['activeTrades', selectedPortfolio],
    queryFn: () => getActiveTrades({ selectedPortfolio }),
  });
};
