import { FullScreenLoader } from 'components/elements';
import { ContentLayout } from 'components/layout';
import React from 'react';
import { EditProfile } from '../components/EditProfile';
import { useAccountDetail } from '../api/getAccountDetails';

function maskString(inputString) {
  if (inputString.length <= 6) {
    return inputString;
  } else {
    const firstThreeLetters = inputString.slice(0, 3);
    const lastFourLetters = inputString.slice(-4);
    const maskedLetters = '*'.repeat(inputString.length - 6);
    return firstThreeLetters + maskedLetters + lastFourLetters;
  }
}

export const Profile = () => {
  const profileQuery = useAccountDetail();

  function getInitials(firstName, lastName) {
    const initials = `${firstName?.charAt(0) || ''}${
      lastName?.charAt(0) || ''
    }`;
    return initials.toUpperCase();
  }

  if (profileQuery.isLoading) {
    return <FullScreenLoader />;
  }

  if (!profileQuery.data) {
    return null;
  }

  return (
    <ContentLayout
      headerContentLeft={<p className="text-3xl font-bold">Profile</p>}
      // headerContentRight={<EditProfile />}
    >
      <div className="flex flex-col items-center justify-center">
        <div className="">
          <div
            className="h-36 w-36 rounded-full border-2 border-black flex items-center 
          justify-center dark:border-white"
          >
            <p className="text-4xl font-bold text-black dark:text-white">
              {getInitials(
                profileQuery.data?.first_name,
                profileQuery.data?.last_name
              )}
            </p>
          </div>
          <EditProfile id={profileQuery?.data?.user_id?.toString()} />
        </div>
        <div className="my-4 text-center">
          <p className="font-bold text-xl">
            {profileQuery.data?.first_name} {profileQuery.data?.last_name}
          </p>
          <p>@{profileQuery.data?.username}</p>
          <div className="my-4">
            <p>{maskString(`${profileQuery.data?.email}`)}</p>
            {/* <p>{maskString('+256748056849')}</p> */}
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};
