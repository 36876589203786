import React from 'react';
import {
  Landing,
  License,
  NotFound,
  PrivacyPolicy,
  Referral,
  Terms,
  TermsOfUse,
  ThankYou,
} from 'features/misc';
import { useAuth } from 'lib/auth';
import { useRoutes } from 'react-router-dom';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import { PortfolioLanding } from 'features/auth';

export const AppRoutes = () => {
  const auth = useAuth();

  const commonRoutes = [
    { path: '/', element: <Landing /> },
    {
      path: '/verify-email',
      element: <ThankYou />,
    },
    { path: '/on-boarding/portfolio/create', element: <PortfolioLanding /> },
    { path: '/accept-terms-and-conditions', element: <Terms /> },
    { path: '/privacy-policy', element: <PrivacyPolicy /> },
    { path: '/terms-of-use', element: <TermsOfUse /> },
    { path: '/referral', element: <Referral /> },
    { path: '/license', element: <License /> },
    { path: '*', element: <NotFound /> },
  ];

  const routes = auth.user ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
