import { Button } from 'components/elements';
import { Form, FormDrawer, InputField } from 'components/form';
import PropTypes from 'prop-types';

import { useAccountDetail } from '../api/getAccountDetails';
import { useEditProfile } from '../api/editProfile';
import { BiPencil } from 'react-icons/bi';

export const EditProfile = () => {
  const editProfileMutation = useEditProfile();

  const profileQuery = useAccountDetail();

  if (!profileQuery.data) {
    return null;
  }

  return (
    <>
      <FormDrawer
        isDone={editProfileMutation.isSuccess}
        size="sm"
        triggerButton={
          <div className="flex flex-row-reverse z-20 -ml-12 -mt-10">
            <button
              className="h-10 w-10 rounded-full border border-black text-black bg-white
             flex justify-center items-center hover:border-white 
             hover:bg-flamingo-500 hover:text-white"
            >
              <BiPencil className="h-6 w-auto" />
            </button>
          </div>
        }
        title="Edit Account Details"
        submitButton={
          <Button
            form="edit-account"
            type="submit"
            size="sm"
            isLoading={editProfileMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="edit-account"
          onSubmit={async values => {
            await editProfileMutation.mutateAsync({
              data: values,
              id: profileQuery.data?.user_id,
            });
          }}
          options={{
            defaultValues: {
              username: profileQuery.data?.username,
              email: profileQuery.data?.email,
              first_name: profileQuery.data?.first_name,
              last_name: profileQuery.data?.last_name,
            },
          }}
        >
          {({ register, formState }) => (
            <>
              <InputField
                label="Username"
                error={formState.errors['username']}
                registration={register('username')}
              />
              <InputField
                label="Email"
                type="email"
                error={formState.errors['email']}
                registration={register('email')}
              />
              <InputField
                label="Last Name"
                error={formState.errors['last_name']}
                registration={register('last_name')}
              />
              <InputField
                label="First Name"
                error={formState.errors['first_name']}
                registration={register('first_name')}
              />
            </>
          )}
        </Form>
      </FormDrawer>
    </>
  );
};

EditProfile.propTypes = {
  id: PropTypes.string.isRequired,
};
