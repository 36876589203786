export const secondsToTime = seconds => {
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const hoursRemainder = hours % 24;
  const minutesRemainder = minutes % 60;
  const secondsRemainder = seconds % 60;

  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''}`;
  }

  if (hoursRemainder > 0) {
    return `${hoursRemainder} hr${hoursRemainder > 1 ? 's' : ''}`;
  }

  if (minutesRemainder > 0) {
    return `${minutesRemainder} min${minutesRemainder > 1 ? 's' : ''}`;
  }

  return `${secondsRemainder} sec${secondsRemainder > 1 ? 's' : ''}`;
};
