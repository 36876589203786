import React from 'react';
import { Instagram, Linkedin, Twitter } from 'react-feather';
import footerImg from 'assets/footer-logo.svg';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <footer className="bg-indigo-500">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">Legal</h3>
            <ul className="space-y-2">
              <li>
                <Link
                  to="/terms-of-use"
                  className="text-gray-300 hover:text-white"
                >
                  Terms of Use Agreement
                </Link>
              </li>
              <li>
                <Link to="/referral" className="text-gray-300 hover:text-white">
                  Referral Agreement
                </Link>
              </li>
              <li>
                <Link to="/license" className="text-gray-300 hover:text-white">
                  License Agreement
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy-policy"
                  className="text-gray-300 hover:text-white"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>

          {/* Company */}
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">Company</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/about" className="text-gray-300 hover:text-white">
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/contact-us"
                  className="text-gray-300 hover:text-white"
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/blog" className="text-gray-300 hover:text-white">
                  Blog
                </Link>
              </li>
            </ul>
          </div>

          {/* Follow Us */}
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">Follow Us</h3>
            <ul className="flex flex-row items-center space-x-4">
              <li>
                <a
                  href="https://twitter.com/tickerzone"
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-300 hover:text-white"
                >
                  <Twitter className="h-24" />
                </a>
              </li>
              <li>
                <a
                  href="https://instagram.com/tickerzone"
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-300 hover:text-white"
                >
                  <Instagram className="h-24" />
                </a>
              </li>
              <li>
                <a
                  href="https://linkedin.com/company/tickerzone"
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-300 hover:text-white"
                >
                  <Linkedin className="h-24" />
                </a>
              </li>
            </ul>
            <img className="" src={footerImg} alt="Tickerzone Logo" />
          </div>
        </div>
      </div>
      <div className="pb-8 text-white text-center text-sm">
        Tickerzone Inc. 2023. All rights reserved.
      </div>
    </footer>
  );
};
