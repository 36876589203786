import { useAuth } from 'lib/auth';
import React from 'react';

import { AuthLayout } from '../components/AuthLayout';
import { Button } from 'components/elements';
import { CreatePortfolio } from 'features/portfolios/components/CreatePortfolio';

export const PortfolioLanding = () => {
  const { user } = useAuth();

  return (
    <AuthLayout option="login">
      <div className="">
        <h1 className="font-bold text-4xl my-12">
          Welcome, {user.first_name}{' '}
        </h1>
        <div className="md:mr-24">
          <div className="text-2xl font-bold">Create a portfolio</div>
          <div className="my-2">
            Before we proceed to your dashboards, how would you like to create
            your first portfolio?
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="">
              <Button
                className="w-full py-5"
                variant="outline"
                disabled
                style={{ cursor: 'not-allowed' }}
              >
                Link to Brokerage
              </Button>
            </div>

            <div className="">
              <CreatePortfolio title="Manual Entry" />
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};
