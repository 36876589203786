import clsx from 'clsx';

import { FieldWrapper } from './FieldWrapper';
import { useDarkMode } from 'hooks/useDarkMode';

export const SelectField = props => {
  const {
    label,
    options,
    error,
    className,
    defaultValue,
    registration,
    placeholder,
  } = props;

  const darkMode = useDarkMode();

  return (
    <FieldWrapper label={label} error={error}>
      <select
        placeholder={placeholder}
        name="location"
        className={`${
          darkMode[0] ? `bg-dim-100 border:none` : `bg-gray-100`
        } ${clsx(
          'mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md',
          className
        )}`}
        defaultValue={defaultValue}
        {...registration}
      >
        {options.map(({ label, value }) => (
          <option key={label?.toString()} value={value}>
            {label}
          </option>
        ))}
      </select>
    </FieldWrapper>
  );
};
