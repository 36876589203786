import { lazyImport } from 'utils/lazyImport';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { FullScreenLoader } from 'components/elements';

const { WatchlistRoutes } = lazyImport(
  () => import('features/watchlist'),
  'WatchlistRoutes'
);
const { OverviewRoutes } = lazyImport(
  () => import('features/overview'),
  'OverviewRoutes'
);
const { SupportRoutes } = lazyImport(
  () => import('features/support'),
  'SupportRoutes'
);
const { SettingsRoutes } = lazyImport(
  () => import('features/settings'),
  'SettingsRoutes'
);
const { SearchRoutes } = lazyImport(
  () => import('features/search'),
  'SearchRoutes'
);
const { PortfoliosRoutes } = lazyImport(
  () => import('features/portfolios'),
  'PortfoliosRoutes'
);
const { MarketsRoutes } = lazyImport(
  () => import('features/markets'),
  'MarketsRoutes'
);
const { DiscoverRoutes } = lazyImport(
  () => import('features/discover'),
  'DiscoverRoutes'
);
const { NotificationsRoutes } = lazyImport(
  () => import('features/notifications'),
  'NotificationsRoutes'
);
const { AccountRoutes } = lazyImport(
  () => import('features/account'),
  'AccountRoutes'
);

const { TradelogsRoutes } = lazyImport(
  () => import('features/tradelogs'),
  'TradelogsRoutes'
);

const App = () => {
  return (
    <>
      <Suspense fallback={<FullScreenLoader />}>
        <Outlet />
      </Suspense>
    </>
  );
};

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      { path: '/app/discover/*', element: <DiscoverRoutes /> },
      { path: '/app/markets/*', element: <MarketsRoutes /> },
      { path: '/app/portfolios/*', element: <PortfoliosRoutes /> },
      { path: '/app/search/*', element: <SearchRoutes /> },
      { path: '/app/settings/*', element: <SettingsRoutes /> },
      { path: '/app/support/*', element: <SupportRoutes /> },
      { path: '/app/account/*', element: <AccountRoutes /> },
      { path: '/app/trades/logs/*', element: <TradelogsRoutes /> },
      { path: '/app/watchlist/*', element: <WatchlistRoutes /> },
      { path: '/app/notifications/*', element: <NotificationsRoutes /> },
      { path: '/app/', element: <OverviewRoutes /> },
    ],
  },
];
