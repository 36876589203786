import { useAddPosition } from '../api/addPosition';
// import * as z from 'zod';
import { usePortfolios } from '../api/getPortfolios';
import {
  Form,
  FormDrawer,
  InputField,
  ReactSelectField,
} from 'components/form';
import { Button } from 'components/elements';
import { useTickers } from '../api/getTickers';
import { useState, useMemo } from 'react';

export const AddPosition = ({ triggerButton }) => {
  const addPositionMutation = useAddPosition();

  const tickersQuery = useTickers();

  const portfoliosQuery = usePortfolios();

  const [searchInput, setSearchInput] = useState('');

  const filteredOptions = useMemo(() => {
    if (!tickersQuery.data) {
      return []; // Return an empty array when tickersQuery.data is null
    }

    const lowercasedInput = searchInput.toLowerCase();

    // Check if the searchInput has at least 2 characters before filtering
    if (searchInput.length < 2) {
      return [];
    }

    return tickersQuery.data.filter(
      option =>
        option.ticker?.toLowerCase().includes(lowercasedInput) ||
        option.company_name?.toLowerCase().includes(lowercasedInput)
    );
  }, [tickersQuery.data, searchInput]);

  if (!portfoliosQuery.data || !tickersQuery.data) return null;

  return (
    <>
      <FormDrawer
        size="sm"
        isDone={addPositionMutation.isSuccess}
        triggerButton={triggerButton}
        title="Add Position"
        submitButton={
          <Button
            form="add-position"
            type="submit"
            size="sm"
            isLoading={addPositionMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="add-position"
          onSubmit={async values => {
            await addPositionMutation.mutateAsync({ data: values });
          }}
          // schema={schema}
        >
          {({ register, formState, watch, setValue }) => (
            <>
              <ReactSelectField
                label="Search for ticker"
                editorContent={watch('ticker')}
                setValue={setValue}
                name="ticker"
                required={true}
                defaultValue={watch('ticker')}
                error={formState.errors['ticker']}
                options={filteredOptions.map(p => ({
                  label: `${p.ticker} - ${p.company_name}`,
                  value: p.ticker,
                }))}
                // Pass the setSearchInput function as onInputChange prop
                onInputChange={inputValue => setSearchInput(inputValue)}
              />
              <InputField
                label="Quantity"
                type="number"
                error={formState.errors['quantity']}
                required={true}
                registration={register('quantity')}
              />
              <InputField
                label="Entry Price"
                type="number"
                required={true}
                error={formState.errors['entry_price']}
                registration={register('entry_price')}
              />
              <InputField
                label="Price Target"
                type="number"
                required={true}
                error={formState.errors['price_target']}
                registration={register('price_target')}
              />
              <ReactSelectField
                label="Select Portfolio"
                editorContent={watch('portfolio_pk')}
                setValue={setValue}
                name="portfolio_pk"
                required={true}
                defaultValue={watch('portfolio_pk')}
                error={formState.errors['portfolio_pk']}
                options={portfoliosQuery.data?.map(p => ({
                  label: p.portfolio_name,
                  value: p.portfolio_id,
                }))}
              />
            </>
          )}
        </Form>
      </FormDrawer>
    </>
  );
};
