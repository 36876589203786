import React from 'react';
import { FiLock, FiMenu, FiSettings, FiUser } from 'react-icons/fi';
import { Search } from './Search';

import { Menu } from '@headlessui/react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from 'lib/auth';
import { useAccountDetail } from 'features/account';
import { FullScreenLoader } from 'components/elements';

export const AppNavBar = ({ sidebarOpen, setSidebarOpen }) => {
  const { logout } = useAuth();

  const navigate = useNavigate();

  const accountDetailsQuery = useAccountDetail();

  if (accountDetailsQuery.isLoading) {
    return <FullScreenLoader />;
  }

  if (!accountDetailsQuery.data) return null;

  function getInitials(firstName, lastName) {
    const initials = `${firstName?.charAt(0) || ''}${
      lastName?.charAt(0) || ''
    }`;
    return initials.toUpperCase();
  }

  return (
    <div
      className="flex justify-between items-center max-w-7xl mx-auto px-2 sm:px-6
     lg:px-8 py-8 lg:sticky lg:top-0 lg:bg-white/[.9] dark:bg-dim-300"
    >
      <div className="space-x-4 md:space-x-8 flex">
        <button
          onClick={() => {
            setSidebarOpen(!sidebarOpen);
          }}
        >
          <FiMenu className="text-gray-700h-4  md:h-8 w-auto" />
        </button>
        <Search />
      </div>
      <div className="flex relative space-x-4 md:space-x-8 items-center md:mr-12">
        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button>
            <div
              className="bg-black font-[1.5rem] text-white h-8 w-8 md:h-12 md:w-12 bg-cover 
            rounded-full flex items-center justify-center dark:border dark:border-white"
            >
              {getInitials(
                accountDetailsQuery.data?.first_name,
                accountDetailsQuery.data?.last_name
              )}
            </div>
          </Menu.Button>
          <Menu.Items
            className="absolute z-40 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100
         rounded-md bg-white dark:bg-dim-200 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div className="px-8 space-y-2 my-2">
              <Menu.Item>
                <Link
                  to="/app/account/profile"
                  className="flex space-x-4 text-gray-800 dark:text-gray-200 dark:hover:text-flamingo-500 py-2 hover:text-flamingo-500 hover:text-bold"
                >
                  <FiUser />
                  <span>Profile</span>
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link
                  to="/app/settings"
                  className="flex space-x-4 text-gray-800 dark:text-gray-200 dark:hover:text-flamingo-500 py-2 hover:text-flamingo-500 hover:text-bold"
                >
                  <FiSettings />
                  <span>Settings</span>
                </Link>
              </Menu.Item>
              <Menu.Item>
                <button
                  type="button"
                  onClick={() => {
                    navigate('/');
                    logout();
                  }}
                  className="flex space-x-4 text-gray-800 dark:text-gray-200 dark:hover:text-flamingo-500 py-2 hover:text-flamingo-500 hover:text-bold"
                >
                  <FiLock />
                  <span>Log out</span>
                </button>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Menu>
      </div>
    </div>
  );
};
