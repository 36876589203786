import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getAccountDetails = () => {
  return axios.get(`/portfolio/account/details`);
};

export const useAccountDetail = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['accountDetails'],
    queryFn: () => getAccountDetails(),
  });
};
