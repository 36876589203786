import React from 'react';
import { Heading } from 'components/layout/components/Heading';
import charts from 'assets/charts.png';
import dashboard from 'assets/dashboard.png';
import realEstate from 'assets/real-estate.png';

export const Portfolio = () => {
  return (
    <div className="w-5/6 mx-auto my-8 md:mb-14 -mt-0 pb-8 pt-20">
      <Heading color="black">Monitor Your Portifolio with Ease .</Heading>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-12 my-8">
        <div className="text-center">
          <img
            src={charts}
            alt="charts"
            className="rounded-md shadow-lg my-2 h-40 w-auto mx-auto"
          />
          <h3>Track Performance</h3>
          <p className="px-4">
            Easily access real-time performance data for all your investments in
            one place.
          </p>
        </div>
        <div className="text-center">
          <img
            src={dashboard}
            alt="board"
            className="rounded-md shadow-lg my-2 h-40 w-auto mx-auto"
          />
          <h3>Manage Risk</h3>
          <p className="px-4">
            Create customized watchlists and alerts to stay on top of your
            investments and mitigate risk.
          </p>
        </div>
        <div className="text-center">
          <img
            src={realEstate}
            alt="real estate"
            className="rounded-md shadow-lg my-2 h-40 w-auto mx-auto"
          />
          <h3>Stay Connected</h3>
          <p className="px-4">
            Take Tickerzone with you on the go with our mobile app and stay
            connected to your investments 24/7
          </p>
        </div>
      </div>
    </div>
  );
};
