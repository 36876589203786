import { Navigate, Route, Routes } from 'react-router-dom';

import { Portfolios } from './Portfolios';
import { CreatePortfolio } from './CreatePortfolio';
import { CreatePortfolioSuccess } from './CreatePortfolioSuccess';

export const PortfoliosRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Portfolios />} />
      <Route path="create" element={<CreatePortfolio />} />
      <Route path="create/success" element={<CreatePortfolioSuccess />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
