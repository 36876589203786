import { Button, ConfirmationDialog } from 'components/elements';
import PropTypes from 'prop-types';

import { useDeleteActiveTrade } from '../api/deleteActiveTrade';

export const DeleteActiveTrade = ({ tradeId, ticker }) => {
  const deleteActiveTradeMutation = useDeleteActiveTrade();

  return (
    <>
      <ConfirmationDialog
        icon="danger"
        title="Delete Active Trade"
        body={`Are you sure you want to remove ${ticker} from holdings?`}
        triggerButton={
          <button className="flex items-start m-2 mx-4">
            <span>Remove</span>
          </button>
        }
        confirmButton={
          <Button
            isLoading={deleteActiveTradeMutation.isLoading}
            type="button"
            className="bg-red-600"
            onClick={async () =>
              await deleteActiveTradeMutation.mutateAsync({ tradeId })
            }
          >
            Delete Trade
          </Button>
        }
      />
    </>
  );
};

DeleteActiveTrade.propTypes = {
  tradeId: PropTypes.string.isRequired,
};
