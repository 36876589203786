import React from 'react';
import { Heading } from 'components/layout/components/Heading';
import abstract from 'assets/abstract.png';

export const Solutions = () => {
  return (
    <div className="grid grid-cols-12 gap-6 bg-indigo-500 dark:bg-dim-300">
      <div className="hidden md:flex md:col-span-4 w-full">
        <img src={abstract} alt="abstract" className="w-auto" />
      </div>
      <div
        className="col-span-12 md:col-span-8 flex flex-col justify-center items-center
			  w-5/6 mx-auto pt-8 pb-16"
      >
        <Heading color="white">
          Targeted Solutions for Retail Investors .
        </Heading>
        <div className="my-8 space-y-8 w-full md:w-4/6 mx-auto">
          <div className="flex space-x-4 text-white items-start">
            <div className="flex items-center">
              <div className="px-4 py-2 bg-gray-600 rounded h-fit">
                <p className="text-white text-xl font-bold">1</p>
              </div>
              <div className="w-12 h-1 bg-gray-600"></div>
            </div>
            <div className="space-y-2">
              <h4 className="my-0">Are you an enterprising Investor?</h4>
              <p>
                Tickerzone provides targeted investment planning tools that
                allow you to invest with confidence.
              </p>
            </div>
          </div>
          <div className="flex space-x-4 text-white items-start">
            <div className="flex items-center">
              <div className="px-4 py-2 bg-gray-600 rounded h-fit">
                <p className="text-white text-xl font-bold">2</p>
              </div>
              <div className="w-12 h-1 bg-gray-600"></div>
            </div>
            <div className="space-y-2">
              <h4 className="my-0">Juggling multiple tools?</h4>
              <p>
                Tickerzone offers a simple and user-friendly alternative that
                allows you to manage all your portfolios in one place.
              </p>
            </div>
          </div>
          <div className="flex space-x-4 text-white items-start">
            <div className="flex items-center">
              <div className="px-4 py-2 bg-gray-600 rounded h-fit">
                <p className="text-white text-xl font-bold">3</p>
              </div>
              <div className="w-12 h-1 bg-gray-600"></div>
            </div>
            <div className="space-y-2">
              <h4 className="my-0">Paying too much for complexity ?</h4>
              <p>
                Nothing beats free.99 for the all the basic tracking you need.
                TickerZone offers a more affordable and accessible alternative
                that presents actionable items at your fingertips.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
