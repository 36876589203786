import { Button, FullScreenLoader } from 'components/elements';
import { ContentLayout } from 'components/layout';
import React, { useEffect, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { HoldingsList } from '../components/HoldingsList';
import { usePortfolios } from '../api/getPortfolios';
import { useActiveTrades } from '../api/getActiveTrades';
import { useNavigate } from 'react-router-dom';
import storage from 'utils/storage';
import { AddPosition } from '../components/AddPosition';

export const Portfolios = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedPortfolio, setSelectedPortfolio] = useState(
    storage.getActivePortfolio()
  );
  const [selectedPortfolioName, setSelectedPortfolioName] = useState();

  const portfoliosQuery = usePortfolios();

  const activeTradesQuery = useActiveTrades({
    selectedPortfolio,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const getPortfolioData = () => {
      if (portfoliosQuery.data && portfoliosQuery.data.length > 0) {
        const savedPortfolioId = storage.getActivePortfolio();
        // console.log(savedPortfolioId);
        if (savedPortfolioId) {
          const selected = portfoliosQuery.data.find(
            p => p.portfolio_id === savedPortfolioId
          );
          setSelectedPortfolio(selected?.portfolio_id);
          setSelectedPortfolioName(selected?.portfolio_name);
        } else {
          setSelectedPortfolio(portfoliosQuery.data[0].portfolio_id);
          setSelectedPortfolioName(portfoliosQuery.data[0].portfolio_name);
        }
      }
    };
    getPortfolioData();
  }, [portfoliosQuery.data]);

  if (portfoliosQuery.isLoading || activeTradesQuery.isLoading) {
    return <FullScreenLoader />;
  }

  if (!portfoliosQuery.data || !activeTradesQuery.data) {
    return null;
  }

  if (!activeTradesQuery.data) return null;
  if (!portfoliosQuery.data) return null;

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handlePortfolioSelect = option => {
    setSelectedPortfolio(option.portfolio_id);
    setSelectedPortfolioName(option.portfolio_name);
    setShowDropdown(false);

    // Save the selectedPortfolioId to storage
    storage.setActivePortfolio(option.portfolio_id);
  };

  return (
    <ContentLayout
      page="portfolios"
      headerContentLeft={
        <div>
          <div className="text-3xl font-semibold md:font-bold">Holdings</div>
          <div className="">
            <span className="text-flamingo-500 text-sm md:text-md">
              My Portfolios
            </span>{' '}
            /{' '}
            <span
              className="cursor-pointer text-gray-500 text-sm md:text-md"
              onClick={handleDropdownToggle}
            >
              {selectedPortfolioName}
              <ChevronDown className="inline-block w-4 h-4 ml-1" />
            </span>
            {showDropdown && (
              <div
                className="origin-top-left absolute z-20  mt-2 w-64 rounded-md shadow-lg 
              bg-white dark:bg-dim-400 ring-1 ring-black ring-opacity-5"
              >
                <div className="py-1" role="menu" aria-orientation="vertical">
                  {portfoliosQuery.data?.map((option, index) => (
                    <div
                      key={index}
                      className="block px-4 py-2 text-sm text-gray-700 cursor-pointer 
                      hover:bg-gray-100 dark:text-gray-300 hover:dark:bg-dim-300"
                      onClick={() => handlePortfolioSelect(option)}
                    >
                      {option.portfolio_name}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      }
      headerContentRight={
        <>
          <div className="hidden md:flex flex-col md:flex-row md:space-x-2 items-center justify-center">
            <div className="flex flex-row space-x-2 items-center">
              <AddPosition
                triggerButton={
                  <Button
                    size="sm"
                    variant="outline"
                    className="whitespace-nowrap w-full text-sm"
                  >
                    <span>Add Position</span>
                  </Button>
                }
              />
            </div>
            <Button
              size="sm"
              className="whitespace-nowrap w-full text-sm"
              onClick={() => navigate('/app/portfolios/create')}
            >
              Create Portfolio
            </Button>
          </div>
          <div className="md:hidden">
            <div className="inline-block w-1/2 pr-2">
              <Button
                size="sm"
                className="text-sm"
                variant="darkoutline"
                onClick={() => navigate('/app/trades/logs')}
              >
                Trades
              </Button>
            </div>
            <div className="inline-block w-1/2">
              <AddPosition
                triggerButton={
                  <Button
                    size="sm"
                    variant="outline"
                    className="whitespace-nowrap w-full text-sm"
                  >
                    <span>Add Position</span>
                  </Button>
                }
              />
            </div>
            <div className="w-full my-2">
              <Button
                size="sm"
                variant="primary"
                onClick={() => navigate('/app/portfolios/create')}
              >
                Create Portfolio
              </Button>
            </div>
          </div>
        </>
      }
    >
      <div className="w-80 md:w-[47rem] lg:w-full overflow-x-auto lg:overflow-visible">
        <HoldingsList data={activeTradesQuery.data} />
      </div>
    </ContentLayout>
  );
};
