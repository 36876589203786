import { Heading } from 'components/layout/components/Heading';
import React from 'react';

export const Features = () => {
  return (
    <div className="bg-flamingo-500 px-8">
      <div className="w-full lg:w-5/6 mx-auto py-8">
        <Heading color="white">Empower Retail Investors .</Heading>
        <div className="my-8 space-y-8 w-full md:w-4/6 mx-auto">
          <div className="flex space-x-4 space-y-0 text-white items-start">
            <div className="flex items-center">
              <div className="px-4 py-2 bg-white rounded">
                <p className="text-flamingo-500 text-xl font-bold">1</p>
              </div>
              <div className="w-12 h-1 bg-white"></div>
            </div>
            <div className="space-y-2">
              <h3 className="my-0">Accessible</h3>
              <p>
                We believe in democratizing access to financial tools and
                empowering investors of all skill levels
              </p>
            </div>
          </div>
          <div className="flex space-x-4 text-white items-start">
            <div className="flex items-center">
              <div className="px-4 py-2 bg-white rounded">
                <p className="text-flamingo-500 text-xl font-bold">2</p>
              </div>
              <div className="w-12 h-1 bg-white"></div>
            </div>
            <div className="space-y-2">
              <h3 className="my-0">Disciplined</h3>
              <p>
                Our target setting software and investment planning tools are
                designed to help investors stay disciplined and focused on their
                long-term goals.
              </p>
            </div>
          </div>
          <div className="flex space-x-4 text-white items-start">
            <div className="flex items-center">
              <div className="px-4 py-2 bg-white rounded">
                <p className="text-flamingo-500 text-xl font-bold">3</p>
              </div>
              <div className="w-12 h-1 bg-white"></div>
            </div>
            <div className="space-y-2">
              <h3 className="my-0">Transparent</h3>
              <p>
                We prioritize transparency and act in the best interest of our
                users. Your needs are our top priority.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
