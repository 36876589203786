import { Table } from 'components/elements';
import { DeleteActiveTrade } from './DeleteActiveTrade';
import { PriceChart } from 'components/charts';
import { UpdateTrade } from './UpdateTrade';
import { UpdatePosition } from './UpdatePosition';

import { roundOff } from 'lib/round-off';
import { BiDotsVertical } from 'react-icons/bi';
import { secondsToTime } from 'utils/secondsToTime';

import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import '@szhsin/react-menu/dist/theme-dark.css';
import { useDarkMode } from 'hooks/useDarkMode';

export const HoldingsList = ({ data }) => {
  const darkMode = useDarkMode();

  return (
    <Table
      data={data}
      columns={[
        {
          title: 'Ticker',
          field: 'ticker',
          Cell({ entry: { ticker } }) {
            return <>{ticker}</>;
          },
        },
        {
          title: 'Quantity',
          field: 'quantity',
          Cell({ entry: { quantity } }) {
            return <>{quantity}</>;
          },
        },
        {
          title: 'Price',
          field: 'price',
          Cell({ entry: { price } }) {
            return <>${roundOff(price, 0.01)}</>;
          },
        },
        {
          title: 'Portfolio Weight',
          field: 'portfolio_weight',
          Cell({ entry: { portfolio_weight } }) {
            return <>{portfolio_weight}%</>;
          },
        },
        {
          title: 'Gain',
          field: 'percentage_gain',
          Cell({ entry: { percentage_gain } }) {
            return <>{roundOff(percentage_gain, 0.01)}%</>;
          },
        },
        {
          title: 'Duration',
          field: 'duration',
          Cell({ entry: { duration_seconds } }) {
            return <>{secondsToTime(duration_seconds)}</>;
          },
        },
        {
          title: 'Price Target',
          field: 'achieved_price_target',
          Cell({ entry: { price_target } }) {
            return <>${roundOff(price_target, 0.01)}</>;
          },
        },
        {
          title: 'Price Chart',
          field: 'id',
          Cell({ entry: { entry_price, price_target, price } }) {
            return (
              <PriceChart
                entryPrice={entry_price}
                targetPrice={price_target}
                currentPrice={price}
              />
            );
          },
        },
        {
          title: '',
          field: 'id',
          Cell({
            entry: {
              id,
              quantity,
              entry_price,
              price_target,
              price,
              notif_trigger_pct_gain_low,
              notif_trigger_pct_gain_high,
              action,
              ticker,
            },
          }) {
            return (
              <Menu
                theming={darkMode[0] ? 'dark' : ''}
                menuButton={
                  <MenuButton>
                    <BiDotsVertical className="h-4" />
                  </MenuButton>
                }
                transition
              >
                <MenuItem>
                  <UpdateTrade
                    pk={id}
                    quantity={quantity}
                    entry_price={entry_price}
                    price_target={price_target}
                    ticker={ticker}
                    notif_trigger_pct_gain_low={notif_trigger_pct_gain_low}
                    notif_trigger_pct_gain_high={notif_trigger_pct_gain_high}
                  />
                </MenuItem>
                <MenuItem>
                  <UpdatePosition
                    pk={id}
                    avg_price={price}
                    quantity={quantity}
                    ticker={ticker}
                    price_target={price_target}
                    action={action}
                  />
                </MenuItem>
                <MenuItem>
                  <DeleteActiveTrade tradeId={id} ticker={ticker} />
                </MenuItem>
              </Menu>
            );
          },
        },
      ]}
    />
  );
};
