import React from 'react';
import { Button } from 'components/elements';
import { Form, FormDrawer, InputField } from 'components/form';

// import * as z from 'zod';
import { useUpdateTrade } from '../api/updateTrade';
import { useDarkMode } from 'hooks/useDarkMode';

// const schema = z.object({
//   quantity: z.number().min(1),
//   entry_price: z.number().min(1),
//   price_target: z.number().min(1),
//   notif_trigger_pct_gain_low: z.string().min(1, 'Required'),
//   notif_trigger_pct_gain_high: z.string().min(1, 'Required'),
// });

export const UpdateTrade = ({
  quantity,
  entry_price,
  price_target,
  notif_trigger_pct_gain_low,
  notif_trigger_pct_gain_high,
  pk,
  ticker,
}) => {
  const updateTradeMutation = useUpdateTrade();

  const darkMode = useDarkMode();

  return (
    <>
      <FormDrawer
        size="sm"
        isDone={updateTradeMutation.isSuccess}
        triggerButton={
          <button className="flex items-start m-2 mx-4">
            <span>Edit</span>
          </button>
        }
        title={`Update ${ticker} fields`}
        submitButton={
          <Button
            form="update-watchlist-trade"
            type="submit"
            size="sm"
            isLoading={updateTradeMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="update-watchlist-trade"
          onSubmit={async values => {
            values.pk = pk;
            const data = Object.fromEntries(
              Object.entries(values).filter(([key, value]) => value !== '')
            );
            await updateTradeMutation.mutateAsync({
              data,
            });
          }}
          options={{
            defaultValues: {
              quantity,
              entry_price,
              price_target,
            },
          }}
          // schema={schema}
        >
          {({ register, formState }) => (
            <>
              <InputField
                type="text"
                label="Entry price"
                required={true}
                error={formState.errors['entry_price']}
                registration={register('entry_price')}
                caption=""
              />
              <InputField
                type="number"
                label="Quantity"
                required={true}
                error={formState.errors['quantity']}
                registration={register('quantity')}
              />
              <InputField
                type="number"
                label="Target entry price"
                required={true}
                error={formState.errors['price_target']}
                registration={register('price_target')}
              />
              <div
                className={`${
                  darkMode[0] ? `bg-dim-300` : `bg-gray-200`
                } rounded-md px-4`}
              >
                <div
                  className={`${
                    darkMode[0] ? `text-gray-300` : `text-gray-800`
                  } text-sm py-3 pt-5 uppercase font-bold p-2`}
                >
                  % Gain notification settings
                </div>
                <div className="p-2">
                  <InputField
                    type="number"
                    required={true}
                    label="Set Low Limit (%)"
                    error={formState.errors['notif_trigger_pct_gain_low']}
                    registration={register('notif_trigger_pct_gain_low')}
                  />
                  <InputField
                    type="number"
                    required={true}
                    label="Set High Limit (%)"
                    error={formState.errors['notif_trigger_pct_gain_high']}
                    registration={register('notif_trigger_pct_gain_high')}
                  />
                </div>
              </div>
            </>
          )}
        </Form>
      </FormDrawer>
    </>
  );
};
