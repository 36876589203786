import { isValidJSON } from './isValidJSON';

const storagePrefix = 'msw-db';

const storage = {
  getToken: () => {
    const token = window.localStorage.getItem(`${storagePrefix}token`);
    return isValidJSON(token) ? JSON.parse(token) : null;
  },
  getRefreshToken: () => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}refreshtoken`)
    );
  },
  setToken: token => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  setErrorMessage: error => {
    window.localStorage.setItem('errorMessage', JSON.stringify(error));
  },
  setErrorResponse: error => {
    window.localStorage.setItem('errorResponse', JSON.stringify(error));
  },
  setActivePortfolio: portfolio_id => {
    window.localStorage.setItem(
      `selectedPortfolioId`,
      JSON.stringify(portfolio_id)
    );
  },
  setActivePortfolioName: portfolio => {
    window.localStorage.setItem(`selectedPortfolio`, JSON.stringify(portfolio));
  },
  getActivePortfolioName: () => {
    return JSON.parse(window.localStorage.getItem('selectedPortfolioName'));
  },
  getErrorMessage: () => {
    return JSON.parse(window.localStorage.getItem('errorMessage'));
  },
  getErrorResponse: () => {
    return JSON.parse(window.localStorage.getItem('errorResponse'));
  },
  clearError: () => {
    window.localStorage.removeItem(`errorMessage`);
    window.localStorage.removeItem(`errorResponse`);
  },
  getActivePortfolio: () => {
    return JSON.parse(window.localStorage.getItem(`selectedPortfolioId`));
  },
  setRefreshToken: token => {
    window.localStorage.setItem(
      `${storagePrefix}refreshtoken`,
      JSON.stringify(token)
    );
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
  clearStorage: () => {
    window.localStorage.clear();
  },
};

export default storage;
