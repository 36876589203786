import React from 'react';
import { AppLayout } from './AppLayout';
import { useDarkMode } from 'hooks/useDarkMode';

export const ContentLayout = ({
  children,
  headerContentLeft,
  headerContentRight,
  page,
}) => {
  const darkMode = useDarkMode();

  return (
    <div className={`${darkMode[0] ? `dark` : ``} w-full`}>
      <AppLayout page={page}>
        <div className="space-y-8">
          <div
            className="flex flex-col space-y-2 md:flex-row items-start md:items-center 
          md:justify-between"
          >
            <div>{headerContentLeft}</div>
            <div>{headerContentRight}</div>
          </div>
          <div className="my-4">{children}</div>
        </div>
      </AppLayout>
    </div>
  );
};
