import { Button } from 'components/elements';
import { AuthLayout } from 'features/auth/components/AuthLayout';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const ThankYou = () => {
  const navigate = useNavigate();

  return (
    <AuthLayout option="login">
      <div className="">
        <h1 className="font-bold text-4xl my-12">Account Confirmation.</h1>
        <div className="font-normal text-xl my-12 mr-32">
          Thank you for confirming your email account. Please proceed to login
        </div>
        <Button onClick={() => navigate('/auth')}>Sign in</Button>
      </div>
    </AuthLayout>
  );
};
